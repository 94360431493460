<template>
  <div>
    <div class="mb-4" v-if="!isCompletedSetup">
      <router-link class="le-btn btn-outline" :to="{name: 'agency.setup'}" style="background:#FFF;">
        <i class="fa fa-reply" aria-hidden="true"></i> Back
      </router-link>
    </div>
    <vuestic-widget :loading="loading">
      <div v-if="page" v-html="page.content"></div>
    </vuestic-widget>
  </div>
</template>
<script>
export default {
  data () {
    return {
      page: null,
      loading: false,
    }
  },

  mounted () {
    this.getPage('training')
  },

  methods: {
    getPage (key) {
      this.loading = true

      this.$store.dispatch('page/getPagePublic', key)
        .then((res) => {
          this.loading = false
          this.page = res.data.data
        })
        .catch(() => {
          this.loading = false
        })
    },
  },

  computed: {
    isCompletedSetup () {
      return this.$store.getters['auth/isCompletedSetup']
    },
  },
}
</script>
